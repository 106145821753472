import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScrollBehavior } from '../lib/hooks/useScrollBehavior';

export const ScrollManager = () => {
  const location = useLocation();
  const resetScroll = useScrollBehavior();

  useEffect(() => {
    // Reset scroll position when location changes
    resetScroll();
  }, [location.pathname, resetScroll]);

  return null;
};