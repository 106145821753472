import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from '../supabase/client';
import type { User } from '@supabase/supabase-js';

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  clearError: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      user: null,
      loading: false,
      error: null,
      login: async (email: string, password: string) => {
        set({ loading: true, error: null });
        try {
          const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password
          });

          if (error) throw error;

          set({ 
            isAuthenticated: true,
            user: data.user,
            loading: false
          });
        } catch (error) {
          set({ 
            error: 'Invalid email or password',
            loading: false
          });
          throw error;
        }
      },
      logout: async () => {
        set({ loading: true, error: null });
        try {
          const { error } = await supabase.auth.signOut();
          if (error) throw error;
          
          set({ 
            isAuthenticated: false,
            user: null,
            loading: false
          });
        } catch (error) {
          set({ 
            error: 'Failed to sign out',
            loading: false
          });
          throw error;
        }
      },
      clearError: () => set({ error: null })
    }),
    {
      name: 'auth-storage',
    }
  )
);

// Set up auth state listener
supabase.auth.onAuthStateChange((event, session) => {
  useAuthStore.setState({
    isAuthenticated: !!session?.user,
    user: session?.user || null
  });
});