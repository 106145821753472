import React from 'react';
import { Loader2, Save } from 'lucide-react';

interface SaveButtonProps {
  isLoading: boolean;
  isTitleEmpty: boolean;
  onSave: () => void;
}

const SaveButton: React.FC<SaveButtonProps> = ({ isLoading, isTitleEmpty, onSave }) => {
  return (
    <div className="flex justify-end">
      <button
        type="submit"
        onClick={onSave}
        disabled={isLoading || isTitleEmpty}
        className="px-6 py-3 bg-black text-white rounded-md hover:bg-gray-800 disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors flex items-center gap-2"
      >
        {isLoading ? (
          <>
            <Loader2 className="animate-spin h-4 w-4" />
            Saving...
          </>
        ) : (
          <>
            <Save className="h-4 w-4" />
            Save Product
          </>
        )}
      </button>
    </div>
  );
};

export default SaveButton;