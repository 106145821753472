import React from 'react';
import { Loader2 } from 'lucide-react';

interface CartFooterProps {
  total: number;
  isProcessing: boolean;
  onCheckout: () => void;
  onClose: () => void;
}

const CartFooter: React.FC<CartFooterProps> = ({
  total,
  isProcessing,
  onCheckout,
  onClose,
}) => {
  return (
    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
      <div className="flex justify-between text-base font-medium text-gray-900">
        <p>Subtotal</p>
        <p>${total.toFixed(2)}</p>
      </div>
      <p className="mt-0.5 text-sm text-gray-500">
        Shipping and taxes calculated at checkout.
      </p>
      <div className="mt-6">
        <button
          onClick={onCheckout}
          disabled={isProcessing}
          className="w-full flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-black hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isProcessing ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
              Processing...
            </>
          ) : (
            'Checkout'
          )}
        </button>
      </div>
      <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
        <p>
          or{' '}
          <button
            type="button"
            className="font-medium text-black hover:text-gray-800"
            onClick={onClose}
          >
            Continue Shopping
          </button>
        </p>
      </div>
    </div>
  );
};

export default CartFooter;