import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface ImageCarouselProps {
  images: string[];
  title: string;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const startX = useRef(0);
  const isDragging = useRef(false);
  const currentTranslate = useRef(0);
  const prevTranslate = useRef(0);
  const animationRef = useRef<number>();

  const handleTouchStart = (e: React.TouchEvent) => {
    isDragging.current = true;
    startX.current = e.touches[0].clientX;
    carouselRef.current?.classList.remove('transition-transform');
    cancelAnimationFrame(animationRef.current!);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging.current) return;

    const currentX = e.touches[0].clientX;
    const diff = currentX - startX.current;
    currentTranslate.current = prevTranslate.current + diff;

    // Add resistance at the edges
    if (currentIndex === 0 && diff > 0) {
      currentTranslate.current = diff * 0.3;
    } else if (currentIndex === images.length - 1 && diff < 0) {
      currentTranslate.current = prevTranslate.current + diff * 0.3;
    }

    animationRef.current = requestAnimationFrame(updateCarouselPosition);
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
    carouselRef.current?.classList.add('transition-transform');

    const moveThreshold = window.innerWidth * 0.2;
    const diff = currentTranslate.current - prevTranslate.current;

    if (Math.abs(diff) > moveThreshold) {
      if (diff > 0 && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      } else if (diff < 0 && currentIndex < images.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    }

    // Snap back to current index
    currentTranslate.current = -(currentIndex * window.innerWidth);
    prevTranslate.current = currentTranslate.current;
    updateCarouselPosition();
  };

  const updateCarouselPosition = () => {
    if (carouselRef.current) {
      carouselRef.current.style.transform = `translateX(${currentTranslate.current}px)`;
    }
  };

  useEffect(() => {
    const newPosition = -(currentIndex * window.innerWidth);
    currentTranslate.current = newPosition;
    prevTranslate.current = newPosition;
    updateCarouselPosition();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [currentIndex]);

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full overflow-hidden bg-black">
      <div
        ref={carouselRef}
        className="flex will-change-transform touch-pan-y"
        style={{
          WebkitOverflowScrolling: 'touch',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="w-full flex-shrink-0 flex items-center justify-center"
            style={{ width: '100vw' }}
          >
            <div className="relative w-full h-full">
              <div className="aspect-[3/4] md:aspect-square w-full">
                <img
                  src={image}
                  alt={`${title} - View ${index + 1}`}
                  className="absolute inset-0 w-full h-full object-contain"
                  draggable="false"
                  loading={index === 0 ? 'eager' : 'lazy'}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Buttons - Only show on larger screens */}
      <div className="hidden md:block">
        <button
          onClick={() => currentIndex > 0 && goToSlide(currentIndex - 1)}
          className={`absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 backdrop-blur-sm ${
            currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={currentIndex === 0}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>

        <button
          onClick={() => currentIndex < images.length - 1 && goToSlide(currentIndex + 1)}
          className={`absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 backdrop-blur-sm ${
            currentIndex === images.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={currentIndex === images.length - 1}
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>

      {/* Dots Indicator */}
      <div className="absolute bottom-safe-bottom left-0 right-0 flex justify-center gap-2 pb-4">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-2 h-2 rounded-full transition-all ${
              index === currentIndex
                ? 'bg-white w-4'
                : 'bg-white/50'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;