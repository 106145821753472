import React from 'react';
import { UseFormRegister, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { ProductFormData } from '../../../../lib/types/product';
import { conditions } from '../../../../lib/types/product';

interface ConditionSectionProps {
  register: UseFormRegister<ProductFormData>;
  errors: FieldErrors<ProductFormData>;
  setValue: UseFormSetValue<ProductFormData>;
}

const ConditionSection: React.FC<ConditionSectionProps> = ({ register, errors, setValue }) => {
  const standardConditionText = 'In great as found condition. Minor scuffs and blemishes appropriate with age.';

  const handleStandardCondition = () => {
    setValue('conditionDetails', standardConditionText, { shouldDirty: true });
  };

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="space-y-2">
        <label className="block text-sm font-medium mb-1">Condition</label>
        <select
          {...register('condition')}
          className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
        >
          <option value="">Select condition</option>
          {conditions.map((condition) => (
            <option key={condition} value={condition}>
              {condition}
            </option>
          ))}
        </select>
        {errors.condition && (
          <p className="mt-1 text-sm text-red-600">{errors.condition.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium mb-1">Condition Details (Optional)</label>
        <textarea
          {...register('conditionDetails')}
          rows={2}
          className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
          placeholder="Enter condition details"
        />
        <button
          type="button"
          onClick={handleStandardCondition}
          className="mt-2 px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
        >
          Use Standard Description
        </button>
      </div>
    </div>
  );
};

export default ConditionSection;