export const generateProductSchema = (product: {
  id: string;
  title: string;
  description: string;
  price: number;
  category: string;
  photos: string[];
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": product.title,
    "description": product.description,
    "image": product.photos[0],
    "brand": "SHOW PONY PALM BEACH",
    "category": product.category,
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": product.price,
      "availability": "https://schema.org/InStock"
    },
    "url": `https://showponypalmbeach.com/product/${product.id}`
  };
};

export const generateBreadcrumbSchema = (items: { name: string; url: string }[]) => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": items.map((item, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "name": item.name,
      "item": item.url
    }))
  };
};

export const generateMetaTags = (data: {
  title: string;
  description: string;
  image?: string;
  url: string;
}) => {
  const baseUrl = 'https://showponypalmbeach.com';
  const fullUrl = `${baseUrl}${data.url}`;
  const imageUrl = data.image || 'https://res.cloudinary.com/graphichut/image/upload/v1731584320/IMG_4647_ls7vwl.png';

  return {
    title: `${data.title} | SHOW PONY PALM BEACH`,
    meta: [
      {
        name: 'description',
        content: data.description
      },
      {
        property: 'og:title',
        content: data.title
      },
      {
        property: 'og:description',
        content: data.description
      },
      {
        property: 'og:image',
        content: imageUrl
      },
      {
        property: 'og:url',
        content: fullUrl
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'twitter:title',
        content: data.title
      },
      {
        name: 'twitter:description',
        content: data.description
      },
      {
        name: 'twitter:image',
        content: imageUrl
      }
    ]
  };
};