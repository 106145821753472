export const materialCategories = {
  'Wood': [
    'Wood',
    'Oak',
    'Walnut', 
    'Mahogany',
    'Teak',
    'Pine',
    'Maple',
    'Tiger Maple',
    'Cherry',
    'Burl',
    'Olive Wood',
    'Bamboo',
    'Rattan'
  ],
  'Metal': [
    'Metal',
    'Brass',
    'Bronze',
    'Iron',
    'Steel',
    'Aluminum',
    'Copper',
    'Chrome',
    'Nickel'
  ],
  'Glass': [
    'Glass',
    'Clear Glass',
    'Frosted Glass',
    'Stained Glass',
    'Mirrored Glass',
    'Beveled Glass',
    'Tempered Glass'
  ],
  'Stone': [
    'Stone',
    'Marble',
    'Granite',
    'Limestone',
    'Travertine',
    'Slate',
    'Onyx',
    'Coral'
  ],
  'Fabric': [
    'Fabric',
    'Cotton',
    'Linen',
    'Silk',
    'Velvet',
    'Wool',
    'Leather',
    'Canvas',
    'Mohair',
    'Chenille',
    'Paper'
  ],
  'Synthetic': [
    'Synthetic',
    'Acrylic',
    'Lucite',
    'Resin',
    'Fiberglass',
    'Vinyl',
    'Polyester',
    'Oil Paint',
    'Acrylic Paint'
  ],
  'Ceramic': [
    'Ceramic',
    'Porcelain',
    'Earthenware',
    'Stoneware',
    'Terracotta'
  ],
  'Natural Materials': [
    'Natural Materials',
    'Wicker',
    'Cane',
    'Jute',
    'Seagrass',
    'Bamboo',
    'Shell',
    'Horn',
    'Mother of Pearl',
    'Coral',
    'Taxidermy'
  ]
} as const;

export type MaterialCategory = keyof typeof materialCategories;
export type Material = typeof materialCategories[MaterialCategory][number];