import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Product } from '../../lib/types/product';
import { generateProductSchema, generateBreadcrumbSchema } from '../../lib/utils/seo';

interface ProductSEOProps {
  product: Product;
}

const ProductSEO: React.FC<ProductSEOProps> = ({ product }) => {
  const breadcrumbItems = [
    { name: 'Home', url: '/' },
    { name: product.category, url: `/shop/${product.category.toLowerCase()}` },
    { name: product.title, url: `/product/${product.id}` }
  ];

  if (product.subcategory) {
    breadcrumbItems.splice(2, 0, {
      name: product.subcategory,
      url: `/shop/${product.category.toLowerCase()}/${product.subcategory.toLowerCase()}`
    });
  }

  return (
    <Helmet>
      <title>{`${product.title} | SHOW PONY PALM BEACH`}</title>
      <meta name="description" content={product.description} />
      
      {/* OpenGraph Tags */}
      <meta property="og:title" content={product.title} />
      <meta property="og:description" content={product.description} />
      <meta property="og:image" content={product.photos[0]} />
      <meta property="og:url" content={`https://showponypalmbeach.com/product/${product.id}`} />
      <meta property="og:type" content="product" />
      <meta property="product:price:amount" content={product.price.toString()} />
      <meta property="product:price:currency" content="USD" />
      
      {/* Twitter Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={product.title} />
      <meta name="twitter:description" content={product.description} />
      <meta name="twitter:image" content={product.photos[0]} />
      
      {/* Product Schema */}
      <script type="application/ld+json">
        {JSON.stringify(generateProductSchema(product))}
      </script>
      
      {/* Breadcrumb Schema */}
      <script type="application/ld+json">
        {JSON.stringify(generateBreadcrumbSchema(breadcrumbItems))}
      </script>
      
      <link rel="canonical" href={`https://showponypalmbeach.com/product/${product.id}`} />
    </Helmet>
  );
};

export default ProductSEO;