import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Home, Search, Grid } from 'lucide-react';
import SearchDialog from './SearchDialog';
import { categories } from '../../lib/categories';

const MobileNav = () => {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);

  const handleHomeClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCategoryClick = (categoryName: string) => {
    navigate(`/shop/${encodeURIComponent(categoryName.toLowerCase())}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsCategoriesOpen(false);
  };

  return (
    <>
      <div className="fixed bottom-4 left-4 right-4 bg-white border border-gray-200 rounded-full shadow-lg pb-safe-bottom z-50 md:hidden">
        <div className="flex justify-around items-center h-14">
          <button
            onClick={handleHomeClick}
            className="flex flex-col items-center justify-center w-1/3 h-full"
          >
            <Home className="h-5 w-5" />
            <span className="text-xs mt-0.5">Home</span>
          </button>
          
          <button
            onClick={() => setIsCategoriesOpen(true)}
            className="flex flex-col items-center justify-center w-1/3 h-full"
          >
            <Grid className="h-5 w-5" />
            <span className="text-xs mt-0.5">Categories</span>
          </button>

          <button
            onClick={() => setIsSearchOpen(true)}
            className="flex flex-col items-center justify-center w-1/3 h-full"
          >
            <Search className="h-5 w-5" />
            <span className="text-xs mt-0.5">Search</span>
          </button>
        </div>
      </div>

      {/* Categories Dialog */}
      {isCategoriesOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="fixed inset-x-0 bottom-0 bg-white rounded-t-xl pb-safe-bottom">
            <div className="p-4 border-b">
              <h2 className="text-lg font-medium">Categories</h2>
            </div>
            <div className="overflow-auto max-h-[70vh]">
              {categories.map((category) => (
                <button
                  key={category.name}
                  onClick={() => handleCategoryClick(category.name)}
                  className="w-full text-left px-4 py-3 hover:bg-gray-50 flex items-center justify-between"
                >
                  <span>{category.name}</span>
                </button>
              ))}
            </div>
            <div className="p-4 border-t">
              <button
                onClick={() => setIsCategoriesOpen(false)}
                className="w-full py-3 bg-black text-white rounded-full"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <SearchDialog isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
    </>
  );
};

export default MobileNav;