import React, { useEffect } from 'react';
import { MapPin, Truck, Mail } from 'lucide-react';

const Shipping = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white pt-24 pb-16">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-serif text-center mb-12">Shipping Information</h1>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="space-y-12">
            <div>
              <h2 className="text-2xl font-medium mb-8">Delivery Options</h2>
              
              <div className="space-y-8">
                <div className="flex gap-4">
                  <MapPin className="h-6 w-6 flex-shrink-0" />
                  <div>
                    <h3 className="font-medium mb-2">Local Pickup</h3>
                    <p className="text-gray-600">
                      Available from our West Palm Beach location.<br />
                      Please contact us to schedule a pickup time.
                    </p>
                  </div>
                </div>

                <div className="flex gap-4">
                  <Truck className="h-6 w-6 flex-shrink-0" />
                  <div>
                    <h3 className="font-medium mb-2">White Glove Delivery</h3>
                    <p className="text-gray-600">
                      Professional delivery service available within<br />
                      South Florida. Contact us for a quote.
                    </p>
                  </div>
                </div>

                <div className="flex gap-4">
                  <Mail className="h-6 w-6 flex-shrink-0" />
                  <div>
                    <h3 className="font-medium mb-2">Nationwide Shipping</h3>
                    <p className="text-gray-600">
                      We work with trusted shipping partners to deliver<br />
                      nationwide. Rates vary based on location and item size.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-medium mb-6">Important Information</h2>
              <ul className="list-disc pl-5 space-y-3 text-gray-600">
                <li>All items are carefully packaged to ensure safe delivery</li>
                <li>Shipping costs are calculated based on size, weight, and destination</li>
                <li>Insurance is included with all shipments</li>
                <li>Delivery times vary by location and shipping method</li>
                <li>We'll provide tracking information once your item ships</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-medium mb-6">Direct Contact</h2>
              <div className="space-y-2">
                <p>
                  <a href="sms:+19174559307" className="text-gray-600 hover:text-black">
                    (917) 455-9307
                  </a>
                </p>
                <p>
                  <a href="mailto:showponysales@gmail.com" className="text-gray-600 hover:text-black">
                    showponysales@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 p-8 rounded-lg">
            <h2 className="text-2xl font-medium mb-8">Request Shipping Quote</h2>
            <form className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                />
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                />
              </div>

              <div>
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
                  Delivery Location (City, State)
                </label>
                <input
                  type="text"
                  id="location"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                  Message (Include item details if applicable)
                </label>
                <textarea
                  id="message"
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full bg-black text-white py-3 rounded-md hover:bg-gray-800 transition-colors"
              >
                Request Quote
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;