import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Search, ShoppingBag } from 'lucide-react';
import { categories } from '../../lib/categories';

const TabletNav = () => {
  const navigate = useNavigate();
  const [showCategories, setShowCategories] = React.useState(false);

  const handleCategoryClick = (categoryName: string) => {
    navigate(`/shop/${categoryName.toLowerCase()}`);
    setShowCategories(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 pb-safe-bottom z-50 hidden md:block lg:hidden">
        <div className="flex justify-around items-center h-16">
          <button
            onClick={() => setShowCategories(true)}
            className="flex flex-col items-center justify-center w-1/3"
          >
            <Grid className="h-6 w-6" />
            <span className="text-xs mt-1">Categories</span>
          </button>

          <button
            onClick={() => navigate('/search')}
            className="flex flex-col items-center justify-center w-1/3"
          >
            <Search className="h-6 w-6" />
            <span className="text-xs mt-1">Search</span>
          </button>

          <button
            onClick={() => navigate('/cart')}
            className="flex flex-col items-center justify-center w-1/3"
          >
            <ShoppingBag className="h-6 w-6" />
            <span className="text-xs mt-1">Cart</span>
          </button>
        </div>
      </div>

      {/* Categories Modal */}
      {showCategories && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 md:block lg:hidden">
          <div className="absolute inset-x-0 bottom-0 bg-white rounded-t-xl">
            <div className="p-4 border-b">
              <h2 className="text-lg font-medium">Categories</h2>
            </div>
            <div className="overflow-auto max-h-[60vh]">
              {categories.map((category) => (
                <button
                  key={category.name}
                  onClick={() => handleCategoryClick(category.name)}
                  className="w-full text-left px-6 py-4 hover:bg-gray-50 flex items-center justify-between"
                >
                  <span>{category.name}</span>
                </button>
              ))}
            </div>
            <div className="p-4 border-t">
              <button
                onClick={() => setShowCategories(false)}
                className="w-full py-3 bg-black text-white rounded-full"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TabletNav;