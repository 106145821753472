import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '../lib/utils/hooks/useMediaQuery';
import HeroNav from './HeroNav';

const Hero = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleExplore = () => {
    navigate('/shop');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="h-screen">
      <div className="relative h-full">
        <img
          src="https://res.cloudinary.com/graphichut/image/upload/v1733715597/Image_hjokpf.gif"
          alt="Elegant vintage furniture display"
          className="w-full h-full object-cover"
        />
        {!isMobile && <HeroNav />}
        <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center">
          <div className="text-center text-white">
            <h2 className="text-4xl md:text-[50px] font-sans tracking-tight mb-6">SHOW PONY <span className="whitespace-nowrap">PALM BEACH</span></h2>
            <p className="text-xl md:text-2xl font-light tracking-wide mb-8">Exceptional vintage furniture and accessories</p>
            <button 
              onClick={handleExplore}
              className="bg-white/50 backdrop-blur-sm text-black px-8 py-3 text-lg rounded-md hover:bg-white/60 transition-colors transform hover:scale-105 duration-200"
            >
              Explore Collection
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;