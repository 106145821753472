export class OpenAIService {
  static async generateDescription(params: {
    title: string;
    category: string;
    condition: string;
    materials: string[];
    style?: string;
    period?: string;
  }): Promise<string> {
    try {
      const response = await fetch('/.netlify/functions/generate-description', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ message: 'Failed to generate description' }));
        throw new Error(errorData.message || 'Failed to generate description');
      }

      const data = await response.json();
      return data.description;

    } catch (error) {
      console.error('OpenAI error:', error);
      throw error;
    }
  }
}