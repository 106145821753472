import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { ProductFormData } from '../../../../lib/types/product';
import { periods, styles } from '../../../../lib/types/product';

interface StyleSectionProps {
  register: UseFormRegister<ProductFormData>;
}

const StyleSection: React.FC<StyleSectionProps> = ({ register }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <div>
        <label className="block text-sm font-medium mb-1">Period</label>
        <select
          {...register('period')}
          className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
        >
          <option value="">Select period</option>
          {periods.map((period) => (
            <option key={period} value={period}>
              {period}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Style</label>
        <select
          {...register('style')}
          className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
        >
          <option value="">Select style</option>
          {styles.map((style) => (
            <option key={style} value={style}>
              {style}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default StyleSection;