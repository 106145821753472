import { supabase } from '../supabase/client';
import { ProductFormData, Product } from '../types/product';
import { uploadImage } from '../utils/storage';

export class ProductService {
  static async createProduct(data: ProductFormData) {
    try {
      // Upload images first
      const photoPromises = Array.from(data.photos).map(async (file) => {
        if (typeof file === 'string') return file;
        return uploadImage(file);
      });
      const photoUrls = await Promise.all(photoPromises);

      // Generate item number if not provided
      const itemNumber = data.itemNumber || `ITEM-${Date.now()}`;

      // Prepare product data - explicitly set status to draft
      const productData = {
        ...data,
        itemNumber,
        photos: photoUrls,
        status: 'draft', // Force draft status
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      const { error } = await supabase
        .from('products')
        .insert([productData]);

      if (error) {
        console.error('Supabase error:', error);
        throw new Error(`Failed to create product: ${error.message}`);
      }

      return { success: true };
    } catch (error) {
      console.error('Product creation error:', error);
      throw error;
    }
  }

  static async updateProduct(id: string, data: ProductFormData) {
    try {
      // Handle photo updates
      const photoPromises = data.photos.map(async (file) => {
        if (typeof file === 'string') return file;
        return uploadImage(file);
      });
      const photoUrls = await Promise.all(photoPromises);

      // Prepare update data
      const updateData = {
        ...data,
        photos: photoUrls,
        status: 'draft', // Keep as draft when updating
        updated_at: new Date().toISOString(),
      };

      // Remove id from update data if it exists
      delete updateData.id;

      const { error } = await supabase
        .from('products')
        .update(updateData)
        .eq('id', id);

      if (error) {
        console.error('Supabase error:', error);
        throw new Error(`Failed to update product: ${error.message}`);
      }

      return { success: true };
    } catch (error) {
      console.error('Product update error:', error);
      throw error;
    }
  }

  static async getProduct(id: string): Promise<Product> {
    try {
      const { data, error } = await supabase
        .from('products')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (!data) throw new Error('Product not found');
      
      return data as Product;
    } catch (error) {
      console.error('Product fetch error:', error);
      throw error;
    }
  }
}