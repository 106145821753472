import React from 'react';
import { Plus, Minus } from 'lucide-react';
import { useCartStore } from '../../lib/store/cart';
import type { CartItem as CartItemType } from '../../lib/types/cart';

interface CartItemProps {
  item: CartItemType;
}

const CartItem: React.FC<CartItemProps> = ({ item }) => {
  const { removeItem, updateQuantity } = useCartStore();

  return (
    <li className="py-6 flex">
      <div className="flex-shrink-0 w-24 h-24 overflow-hidden rounded-md">
        <img
          src={item.image}
          alt={item.title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="ml-4 flex-1 flex flex-col">
        <div className="flex justify-between text-base font-medium text-gray-900">
          <h3>{item.title}</h3>
          <p className="ml-4">${item.price}</p>
        </div>
        <div className="flex-1 flex items-end justify-between text-sm">
          <div className="flex items-center space-x-2">
            <button
              onClick={() => updateQuantity(item.id, Math.max(0, item.quantity - 1))}
              className="p-1 rounded-md hover:bg-gray-100"
            >
              <Minus className="h-4 w-4" />
            </button>
            <span className="font-medium">{item.quantity}</span>
            <button
              onClick={() => updateQuantity(item.id, item.quantity + 1)}
              className="p-1 rounded-md hover:bg-gray-100"
            >
              <Plus className="h-4 w-4" />
            </button>
          </div>
          <button
            type="button"
            onClick={() => removeItem(item.id)}
            className="font-medium text-red-600 hover:text-red-500"
          >
            Remove
          </button>
        </div>
      </div>
    </li>
  );
};

export default CartItem;