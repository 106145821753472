import React from 'react';

interface CartOverlayProps {
  children: React.ReactNode;
  onClose: () => void;
}

const CartOverlay: React.FC<CartOverlayProps> = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div 
        className="absolute inset-0 bg-black bg-opacity-50" 
        onClick={onClose} 
      />
      <div className="absolute inset-y-0 right-0 max-w-full flex">
        <div className="w-screen max-w-md">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CartOverlay;