import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabase';
import ProductUploader from '../../components/admin/products/ProductUploader';
import { ProductFormData, Product } from '../../lib/types/product';
import { ProductService } from '../../lib/services/product.service';
import { toast } from 'sonner';

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProduct = async () => {
      if (!id) return;
      
      try {
        const { data, error } = await supabase
          .from('products')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;
        setProduct(data);
      } catch (err) {
        const errorMessage = 'Failed to fetch product';
        setError(errorMessage);
        toast.error(errorMessage);
      }
    };

    fetchProduct();
  }, [id]);

  const handleSubmit = async (data: ProductFormData) => {
    if (!id) return;
    
    setIsLoading(true);
    setError(null);

    try {
      await ProductService.updateProduct(id, {
        ...data,
        status: product?.status || 'published',
        itemNumber: product?.itemNumber || `ITEM-${Date.now()}`
      });
      
      toast.success('Product updated successfully');
      navigate('/admin/products');
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to update product';
      setError(errorMessage);
      toast.error(errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  if (!product) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center">
          {error ? (
            <p className="text-red-600">{error}</p>
          ) : (
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black mx-auto"></div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl font-serif">Edit Product</h1>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-600 rounded-md">
          {error}
        </div>
      )}

      <ProductUploader
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initialData={product}
      />
    </div>
  );
};

export default EditProduct;