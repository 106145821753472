import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollBehavior() {
  const location = useLocation();

  const resetScroll = useCallback(() => {
    // Reset main window scroll
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Use instant for consistent behavior across devices
    });

    // Reset all scrollable containers
    const scrollableElements = document.querySelectorAll('.scrollable');
    scrollableElements.forEach(element => {
      if (element instanceof HTMLElement) {
        element.scrollTop = 0;
      }
    });
  }, []);

  // Reset scroll on route change
  useEffect(() => {
    resetScroll();
  }, [location.pathname, resetScroll]);

  // Reset scroll on page load
  useEffect(() => {
    resetScroll();
    
    // Handle browser back/forward
    window.addEventListener('popstate', resetScroll);
    
    return () => {
      window.removeEventListener('popstate', resetScroll);
    };
  }, [resetScroll]);

  return resetScroll;
}