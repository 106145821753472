import { z } from 'zod';
import type { Dimensions } from '../utils/format/dimensions';
import { formatDimensions } from '../utils/format/dimensions';

// Product condition constants
export const conditions = [
  'Excellent',
  'Very Good',
  'Good',
  'Fair',
  'Poor'
] as const;

// Time period constants
export const periods = [
  'Antique',
  'Vintage',
  'Mid-Century',
  'Contemporary',
  'Modern'
] as const;

// Style constants
export const styles = [
  'Art Deco',
  'Art Nouveau',
  'Bauhaus',
  'Mid-Century Modern',
  'Industrial',
  'Traditional',
  'Contemporary',
  'Minimalist',
  'Rustic',
  'Scandinavian',
  'Abstract',
  'Coastal',
  'Boho Chic',
  'Expressionist',
  'Figurative',
  'Hollywood Regency'
] as const;

// Type definitions
export type Condition = typeof conditions[number];
export type Period = typeof periods[number];
export type Style = typeof styles[number];

// Base Product interface
export interface Product {
  id: string;
  itemNumber: string;
  title: string;
  description: string;
  price: number;
  category: string;
  subcategory?: string;
  option?: string;
  condition: Condition;
  conditionDetails?: string;
  period?: Period;
  style?: Style;
  designer?: string;
  manufacturer?: string;
  origin?: string;
  dimensions?: Dimensions;
  materials: string[];
  photos: string[];
  status: 'draft' | 'published';
  created_at: string;
  updated_at: string;
}

// Product form data type
export type ProductFormData = Omit<Product, 'id' | 'created_at' | 'updated_at'>;

// Re-export dimensions utilities
export { Dimensions, formatDimensions };