import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const supabaseUrl = 'https://swobxbjyntepdkjighdp.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN3b2J4Ymp5bnRlcGRramlnaGRwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzI2Mzc0ODQsImV4cCI6MjA0ODIxMzQ4NH0.eBjQKD1NFWZs-7-soJuGElBkBcSbNIOyEEHuUY8eRts';

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase configuration');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  }
});