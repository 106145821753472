import axios from 'axios';

export const removeBackground = async (file: File): Promise<File> => {
  try {
    const formData = new FormData();
    formData.append('image_file', file);

    const response = await axios.post('https://sdk.photoroom.com/v1/segment', formData, {
      headers: {
        'x-api-key': '84e4486127ac0002cd7183456c134892a7373a79',
      },
      responseType: 'blob'
    });

    return new File([response.data], file.name, { type: 'image/png' });
  } catch (error) {
    console.error('PhotoRoom API error:', error);
    throw new Error('Failed to remove background');
  }
};