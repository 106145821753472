import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { useCartStore } from '../../lib/store/cart';

const Success = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clearCart = useCartStore(state => state.clearCart);
  
  useEffect(() => {
    // Clear the cart after successful payment
    clearCart();
  }, [clearCart]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-sm text-center">
        <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
        <h1 className="mt-4 text-2xl font-serif">Thank you for your order!</h1>
        <p className="mt-2 text-gray-600">
          We've received your payment and will begin processing your order.
        </p>
        <p className="mt-4 text-sm text-gray-500">
          Order confirmation #{searchParams.get('session_id')?.slice(-8)}
        </p>
        <div className="mt-8">
          <button
            onClick={() => navigate('/')}
            className="w-full px-6 py-3 bg-black text-white rounded-md hover:bg-gray-800"
          >
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;