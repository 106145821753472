import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Home } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { Product } from '../lib/types/product';
import ProductCard from '../components/ProductCard';
import CategorySEO from '../components/seo/CategorySEO';

const Shop = () => {
  const { category, subcategory, option } = useParams();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Reset scroll position when component mounts or params change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [category, subcategory, option]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let query = supabase
          .from('products')
          .select('*')
          .eq('status', 'published');

        if (category) {
          const decodedCategory = decodeURIComponent(category).toLowerCase();
          query = query.ilike('category', `%${decodedCategory}%`);
        }

        if (subcategory) {
          const decodedSubcategory = decodeURIComponent(subcategory).toLowerCase();
          query = query.ilike('subcategory', `%${decodedSubcategory}%`);
        }

        if (option) {
          const decodedOption = decodeURIComponent(option).toLowerCase();
          query = query.ilike('option', `%${decodedOption}%`);
        }

        const { data, error: fetchError } = await query.order('created_at', { ascending: false });

        if (fetchError) throw fetchError;
        setProducts(data || []);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Failed to load products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category, subcategory, option]);

  const handleBackHome = () => {
    window.scrollTo({ top: 0, behavior: 'instant' });
    navigate('/');
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <div key={i} className="animate-pulse">
              <div className="aspect-square bg-gray-200 rounded-lg"></div>
              <div className="mt-4 space-y-2">
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center text-red-600">{error}</div>
      </div>
    );
  }

  const getTitle = () => {
    if (option) return decodeURIComponent(option);
    if (subcategory) return decodeURIComponent(subcategory);
    if (category) return decodeURIComponent(category);
    return 'All Products';
  };

  return (
    <>
      <CategorySEO 
        category={category || 'All Products'} 
        subcategory={subcategory}
        option={option}
        productCount={products.length}
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-serif">{getTitle()}</h1>
          {(category || subcategory || option) && (
            <button
              onClick={handleBackHome}
              className="flex items-center gap-2 px-4 py-2 text-sm bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
            >
              <Home className="h-4 w-4" />
              <span className="hidden sm:inline">Back to Home</span>
            </button>
          )}
        </div>
        
        {products.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500 mb-6">No products found in this category</p>
            <button
              onClick={handleBackHome}
              className="inline-flex items-center gap-2 px-6 py-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
            >
              <Home className="h-5 w-5" />
              Return to Home
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
            {products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Shop;