import React from 'react';
import { Link } from 'react-router-dom';
import { Edit, Trash2, Eye, EyeOff } from 'lucide-react';
import { Product } from '../../../../lib/types/product';
import { formatDimensions } from '../../../../lib/types/product';
import { toggleProductStatus } from './utils';

interface ProductTableRowProps {
  product: Product;
  onDelete: (product: Product) => void;
  onRefresh: () => void;
}

const ProductTableRow: React.FC<ProductTableRowProps> = ({ product, onDelete, onRefresh }) => {
  return (
    <tr className="hover:bg-gray-50">
      <td className="px-6 py-4">
        <div className="flex items-center">
          <div className="h-16 w-16 flex-shrink-0">
            <img
              className="h-16 w-16 rounded-md object-cover"
              src={product.photos[0]}
              alt={product.title}
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {product.title}
            </div>
            <div className="text-sm text-gray-500">
              Item #{product.itemNumber}
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="text-sm text-gray-900">
          <div className="mb-1">
            {product.category}
            {product.subcategory && ` > ${product.subcategory}`}
            {product.option && ` > ${product.option}`}
          </div>
          <div className="text-sm text-gray-500">
            <span className="mr-4">${product.price?.toLocaleString()}</span>
            {product.dimensions && (
              <span>{formatDimensions(product.dimensions)}</span>
            )}
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <button
          onClick={() => toggleProductStatus(product, onRefresh)}
          className={`inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium ${
            product.status === 'published'
              ? 'bg-green-100 text-green-800'
              : 'bg-yellow-100 text-yellow-800'
          }`}
        >
          {product.status === 'published' ? (
            <Eye className="w-3 h-3 mr-1" />
          ) : (
            <EyeOff className="w-3 h-3 mr-1" />
          )}
          {product.status}
        </button>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <div className="flex items-center space-x-3">
          <Link
            to={`/admin/products/edit/${product.id}`}
            className="text-gray-400 hover:text-gray-500"
            title="Edit"
          >
            <Edit className="h-5 w-5" />
          </Link>
          <button
            onClick={() => onDelete(product)}
            className="text-gray-400 hover:text-red-500"
            title="Delete"
          >
            <Trash2 className="h-5 w-5" />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ProductTableRow;