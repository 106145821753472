import React from 'react';
import { Product } from '../../../lib/types/product';
import ProductTableHeader from './ProductTable/ProductTableHeader';
import ProductTableBody from './ProductTable/ProductTableBody';

interface ProductTableProps {
  products: Product[];
  onDelete: (product: Product) => void;
  onRefresh: () => void;
}

const ProductTable: React.FC<ProductTableProps> = ({ products, onDelete, onRefresh }) => {
  return (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <ProductTableHeader />
          <ProductTableBody 
            products={products}
            onDelete={onDelete}
            onRefresh={onRefresh}
          />
        </table>
      </div>
    </div>
  );
};

export default ProductTable;