import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, X } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { Product } from '../lib/types/product';
import { useDebounce } from '../lib/utils/hooks/useDebounce';
import { useMediaQuery } from '../lib/utils/hooks/useMediaQuery';

const SearchBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const debouncedQuery = useDebounce(query, 300);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const searchProducts = async () => {
      if (!debouncedQuery.trim()) {
        setResults([]);
        return;
      }

      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('products')
          .select('*')
          .eq('status', 'published')
          .or(`title.ilike.%${debouncedQuery}%,category.ilike.%${debouncedQuery}%,subcategory.ilike.%${debouncedQuery}%,description.ilike.%${debouncedQuery}%`)
          .limit(5);

        if (error) throw error;
        setResults(data || []);
      } catch (err) {
        console.error('Search error:', err);
      } finally {
        setLoading(false);
      }
    };

    searchProducts();
  }, [debouncedQuery]);

  const handleProductClick = (productId: string) => {
    setIsOpen(false);
    setQuery('');
    navigate(`/product/${productId}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div ref={searchRef} className="relative">
      <button
        onClick={() => setIsOpen(true)}
        className="p-2 hover:bg-gray-50 rounded-md min-h-[44px] min-w-[44px] flex items-center justify-center"
        aria-label="Search"
      >
        <Search className="h-6 w-6 text-gray-900" />
      </button>

      {isOpen && (
        <div 
          className={`fixed ${
            isMobile 
              ? 'inset-x-0 top-safe-top bottom-0 bg-white z-50' 
              : 'absolute top-0 left-0 w-[400px] bg-white border rounded-lg shadow-lg z-50 -translate-x-1/2 sm:translate-x-0'
          }`}
        >
          <div className="p-4 pt-safe-top">
            <div className="relative">
              <input
                ref={inputRef}
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search products..."
                className="w-full pl-10 pr-10 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-black text-base"
                autoComplete="off"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <button
                onClick={() => {
                  setIsOpen(false);
                  setQuery('');
                }}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 p-1"
              >
                <X className="h-5 w-5 text-gray-400 hover:text-gray-600" />
              </button>
            </div>

            {loading && (
              <div className="py-8 text-center text-gray-500">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-black mx-auto"></div>
              </div>
            )}

            {!loading && results.length > 0 && (
              <div className="mt-4 divide-y max-h-[60vh] overflow-y-auto momentum-scroll">
                {results.map((product) => (
                  <button
                    key={product.id}
                    onClick={() => handleProductClick(product.id)}
                    className="w-full flex items-center gap-4 p-4 hover:bg-gray-50 transition-colors"
                  >
                    <img
                      src={product.photos[0]}
                      alt={product.title}
                      className="w-16 h-16 object-cover rounded"
                    />
                    <div className="flex-1 text-left">
                      <h3 className="font-medium">{product.title}</h3>
                      <p className="text-sm text-gray-500">
                        {product.category}
                        {product.subcategory && ` > ${product.subcategory}`}
                        {product.option && ` > ${product.option}`}
                      </p>
                      <p className="text-sm text-gray-500">${product.price.toLocaleString()}</p>
                    </div>
                  </button>
                ))}
              </div>
            )}

            {!loading && query && results.length === 0 && (
              <div className="py-8 text-center text-gray-500">
                <p className="mb-2">No products found</p>
                <p className="text-sm">Try adjusting your search terms or browse our categories</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;