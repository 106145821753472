import React from 'react';
import { useNavigate } from 'react-router-dom';

const categories = [
  'Decor',
  'Fine Art', 
  'Furniture',
  'Lighting',
  'Outdoor',
  'Tableware & Barware'
];

const HeroNav = () => {
  const navigate = useNavigate();

  const handleCategoryClick = (category: string) => {
    navigate(`/shop/${category.toLowerCase()}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="absolute top-0 left-0 right-0 z-10 bg-black/30 backdrop-blur-sm">
      <div className="max-w-[1800px] mx-auto px-4">
        <div className="flex justify-center items-center h-16">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryClick(category)}
              className="px-4 py-2 text-white hover:text-gray-200 transition-colors"
            >
              {category}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeroNav;