import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { ProductFormData } from '../../../lib/types/product';

interface DimensionsProps {
  control: Control<ProductFormData>;
  errors: any;
}

const Dimensions: React.FC<DimensionsProps> = ({ control, errors }) => {
  const category = useWatch({
    control,
    name: "category"
  });

  const subcategory = useWatch({
    control,
    name: "subcategory"
  });

  const option = useWatch({
    control,
    name: "option"
  });

  // Show seat height for dining sets and seating items
  const showSeatHeight = 
    option === 'Dining Sets' || 
    (category === 'Furniture' && 
      ['Seating', 'Accent Chairs', 'Dining Chairs', 'Lounge Chair', 'Office Chairs', 'Sofas'].includes(subcategory || ''));

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-medium mb-6">Dimensions</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <Controller
          name="dimensions.width"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <label className="block text-sm font-medium mb-1">Width</label>
              <div className="relative">
                <input
                  type="number"
                  step="0.1"
                  value={value || ''}
                  onChange={(e) => {
                    const val = e.target.value ? parseFloat(e.target.value) : undefined;
                    onChange(val);
                  }}
                  className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
                  placeholder="0.0"
                />
                <span className="absolute right-3 top-2 text-gray-500">in</span>
              </div>
            </div>
          )}
        />

        <Controller
          name="dimensions.depth"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <label className="block text-sm font-medium mb-1">Depth</label>
              <div className="relative">
                <input
                  type="number"
                  step="0.1"
                  value={value || ''}
                  onChange={(e) => {
                    const val = e.target.value ? parseFloat(e.target.value) : undefined;
                    onChange(val);
                  }}
                  className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
                  placeholder="0.0"
                />
                <span className="absolute right-3 top-2 text-gray-500">in</span>
              </div>
            </div>
          )}
        />

        <Controller
          name="dimensions.height"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <label className="block text-sm font-medium mb-1">Height</label>
              <div className="relative">
                <input
                  type="number"
                  step="0.1"
                  value={value || ''}
                  onChange={(e) => {
                    const val = e.target.value ? parseFloat(e.target.value) : undefined;
                    onChange(val);
                  }}
                  className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
                  placeholder="0.0"
                />
                <span className="absolute right-3 top-2 text-gray-500">in</span>
              </div>
            </div>
          )}
        />

        {showSeatHeight && (
          <Controller
            name="dimensions.seatHeight"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <label className="block text-sm font-medium mb-1">Seat Height</label>
                <div className="relative">
                  <input
                    type="number"
                    step="0.1"
                    value={value || ''}
                    onChange={(e) => {
                      const val = e.target.value ? parseFloat(e.target.value) : undefined;
                      onChange(val);
                    }}
                    className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
                    placeholder="0.0"
                  />
                  <span className="absolute right-3 top-2 text-gray-500">in</span>
                </div>
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Dimensions;