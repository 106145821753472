import React, { useEffect, useState } from 'react';
import { UseFormRegister, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Wand2, Loader2 } from 'lucide-react';
import { ProductFormData } from '../../../lib/types/product';
import { categories } from '../../../lib/categories';
import { OpenAIService } from '../../../lib/services/openai.service';
import { toast } from 'sonner';

interface BasicInfoProps {
  register: UseFormRegister<ProductFormData>;
  errors: FieldErrors<ProductFormData>;
  setValue: UseFormSetValue<ProductFormData>;
  watch: UseFormWatch<ProductFormData>;
}

const BasicInfo: React.FC<BasicInfoProps> = ({ register, errors, setValue, watch }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const selectedCategory = watch('category');
  const selectedSubcategory = watch('subcategory');
  const title = watch('title');
  const materials = watch('materials');
  const condition = watch('condition');
  const style = watch('style');
  const period = watch('period');
  
  const category = categories.find(c => c.name === selectedCategory);
  const subcategory = category?.subcategories?.find(s => s.name === selectedSubcategory);

  useEffect(() => {
    if (!category?.subcategories?.find(s => s.name === selectedSubcategory)) {
      setValue('subcategory', '');
      setValue('option', '');
    }
  }, [selectedCategory, selectedSubcategory, category, setValue]);

  const handleGenerateDescription = async () => {
    if (!title || !selectedCategory || !condition || !materials?.length) {
      toast.error('Please fill in the required fields first (title, category, condition, materials)');
      return;
    }

    setIsGenerating(true);
    try {
      const description = await OpenAIService.generateDescription({
        title,
        category: selectedCategory,
        condition,
        materials,
        style,
        period,
      });
      setValue('description', description, { shouldDirty: true });
      toast.success('Description generated successfully');
    } catch (error) {
      console.error('Description generation error:', error);
      toast.error('Failed to generate description');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm space-y-8">
      <h2 className="text-lg font-medium border-b pb-4">Product Information</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium mb-1">Title</label>
          <input
            {...register('title')}
            className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
            placeholder="Enter product title"
          />
          {errors.title && (
            <p className="text-red-500 text-sm mt-1">{errors.title.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Price</label>
          <div className="relative">
            <span className="absolute left-3 top-2 text-gray-500">$</span>
            <input
              type="number"
              step="0.01"
              {...register('price', { valueAsNumber: true })}
              className="w-full pl-8 pr-4 py-2 border rounded-md focus:ring-black focus:border-black"
            />
          </div>
          {errors.price && (
            <p className="text-red-500 text-sm mt-1">{errors.price.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Category</label>
          <select
            {...register('category')}
            className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
          >
            <option value="">Select a category</option>
            {categories.map((cat) => (
              <option key={cat.name} value={cat.name}>{cat.name}</option>
            ))}
          </select>
          {errors.category && (
            <p className="text-red-500 text-sm mt-1">{errors.category.message}</p>
          )}
        </div>

        {category?.subcategories && (
          <div>
            <label className="block text-sm font-medium mb-1">Subcategory</label>
            <select
              {...register('subcategory')}
              className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
            >
              <option value="">Select a subcategory</option>
              {category.subcategories.map((sub) => (
                <option key={sub.name} value={sub.name}>{sub.name}</option>
              ))}
            </select>
          </div>
        )}

        {subcategory?.options && (
          <div className="col-span-2">
            <label className="block text-sm font-medium mb-1">Type</label>
            <select
              {...register('option')}
              className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
            >
              <option value="">Select type</option>
              {subcategory.options.map((opt) => (
                <option key={opt} value={opt}>{opt}</option>
              ))}
            </select>
          </div>
        )}

        <div className="col-span-2">
          <div className="flex justify-between items-center mb-1">
            <label className="block text-sm font-medium">Description</label>
            <button
              type="button"
              onClick={handleGenerateDescription}
              disabled={isGenerating}
              className="flex items-center gap-2 px-3 py-1 text-sm bg-black text-white rounded-md hover:bg-gray-800 disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              {isGenerating ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <Wand2 className="h-4 w-4" />
                  Generate
                </>
              )}
            </button>
          </div>
          <textarea
            {...register('description')}
            rows={4}
            className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
            placeholder="Enter product description"
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;