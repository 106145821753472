import React from 'react';
import { RotateCw } from 'lucide-react';

interface ImageRotatorProps {
  onRotate: () => void;
  disabled?: boolean;
}

const ImageRotator: React.FC<ImageRotatorProps> = ({ onRotate, disabled }) => {
  return (
    <button
      type="button"
      onClick={onRotate}
      disabled={disabled}
      className="p-1.5 bg-white rounded-full shadow-md hover:bg-gray-100 opacity-0 group-hover:opacity-100 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
      title="Rotate image"
    >
      <RotateCw className="h-4 w-4" />
    </button>
  );
};

export default ImageRotator;