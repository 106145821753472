import { Product } from '../../../../lib/types/product';
import { supabase } from '../../../../lib/supabase';
import { toast } from 'sonner';

export const toggleProductStatus = async (
  product: Product,
  onRefresh: () => void
): Promise<void> => {
  try {
    const newStatus = product.status === 'published' ? 'draft' : 'published';
    
    const { error } = await supabase
      .from('products')
      .update({ status: newStatus })
      .eq('id', product.id);

    if (error) throw error;
    
    onRefresh();
    toast.success(`Product ${newStatus === 'published' ? 'published' : 'unpublished'} successfully`);
  } catch (err) {
    console.error('Error updating product status:', err);
    toast.error('Failed to update product status');
  }
};