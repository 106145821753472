import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Package, PlusCircle, LogOut } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useAuthStore } from '../../lib/store/auth';
import { StatsCard } from '../../components/admin/StatsCard';
import TestStripeButton from '../../components/payment/TestStripeButton';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    totalProducts: 0,
    totalRevenue: 0,
    totalOrders: 0,
    totalCustomers: 0
  });
  const logout = useAuthStore((state) => state.logout);

  useEffect(() => {
    const fetchStats = async () => {
      const { data: products } = await supabase
        .from('products')
        .select('count');
      
      setStats(prev => ({
        ...prev,
        totalProducts: products?.[0]?.count || 0
      }));
    };

    fetchStats();
  }, []);

  const handleSignOut = async () => {
    await logout();
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-serif">Admin Dashboard</h1>
        <button
          onClick={handleSignOut}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-800"
        >
          <LogOut className="h-4 w-4" />
          Sign Out
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatsCard
          title="Total Products"
          value={stats.totalProducts.toString()}
          icon={<Package />}
          trend="+12% from last month"
        />
        <StatsCard
          title="Revenue"
          value={`$${stats.totalRevenue.toLocaleString()}`}
          icon={<Package />}
          trend="+8% from last month"
        />
        <StatsCard
          title="Orders"
          value={stats.totalOrders.toString()}
          icon={<Package />}
          trend="+24% from last month"
        />
        <StatsCard
          title="Customers"
          value={stats.totalCustomers.toString()}
          icon={<Package />}
          trend="+6% from last month"
        />
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h2 className="text-lg font-medium mb-6">Stripe Configuration Test</h2>
        <TestStripeButton />
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm mt-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-medium">Quick Actions</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Link
            to="/admin/products/new"
            className="p-4 border rounded-lg hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-center gap-3">
              <PlusCircle className="h-5 w-5 text-gray-400" />
              <div>
                <h3 className="font-medium">Add Product</h3>
                <p className="text-sm text-gray-500">Create new listing</p>
              </div>
            </div>
          </Link>
          
          <Link
            to="/admin/products"
            className="p-4 border rounded-lg hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-center gap-3">
              <Package className="h-5 w-5 text-gray-400" />
              <div>
                <h3 className="font-medium">Products</h3>
                <p className="text-sm text-gray-500">Manage inventory</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;