import React from 'react';
import { Upload } from 'lucide-react';
import { useDropzone } from 'react-dropzone';

interface DropZoneProps {
  onDrop: (files: File[]) => void;
  isUploading: boolean;
}

const DropZone: React.FC<DropZoneProps> = ({ onDrop, isUploading }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/webp': ['.webp']
    },
    maxFiles: 10,
    maxSize: 10 * 1024 * 1024, // 10MB
    disabled: isUploading,
    onDrop
  });

  return (
    <div
      {...getRootProps()}
      className={`border-2 border-dashed rounded-md p-8 text-center cursor-pointer transition-colors ${
        isDragActive ? 'border-black bg-gray-50' : 'hover:bg-gray-50'
      } ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      <input {...getInputProps()} />
      <Upload className="mx-auto h-12 w-12 text-gray-400" />
      <p className="mt-2">Drag & drop photos here, or click to select</p>
      <p className="text-sm text-gray-500 mt-1">Maximum 10 images, 10MB each</p>
    </div>
  );
};

export default DropZone;