import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, ShoppingBag, User } from 'lucide-react';
import { categories } from '../../lib/categories';
import SearchBar from '../SearchBar';
import CategoryDropdown from './CategoryDropdown';
import { useMediaQuery } from '../../lib/utils/hooks/useMediaQuery';

interface NavbarProps {
  onCartClick: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onCartClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  const handleCategoryClick = (categoryName: string) => {
    navigate(`/shop/${encodeURIComponent(categoryName.toLowerCase())}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsMenuOpen(false);
  };

  return (
    <nav className="w-full bg-white border-b border-gray-200 pt-safe-top">
      <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center gap-2">
            <button
              onClick={() => setIsMenuOpen(true)}
              className="p-2 hover:bg-gray-50 rounded-md min-h-[44px] min-w-[44px] flex items-center justify-center lg:hidden"
              aria-label="Menu"
            >
              <Menu className="h-6 w-6 text-gray-900" />
            </button>
            <div className="hidden lg:block">
              <CategoryDropdown />
            </div>
            <SearchBar />
          </div>
          
          <div className="flex-1 flex justify-center">
            <Link to="/" className="text-xl md:text-2xl font-sans tracking-tight text-center px-2 text-gray-900">
              {isMobile ? 'SHOW PONY' : 'SHOW PONY PALM BEACH'}
            </Link>
          </div>

          <div className="flex items-center gap-2">
            <button 
              className="p-2 hover:bg-gray-50 rounded-md min-h-[44px] min-w-[44px] flex items-center justify-center"
              aria-label="Account"
            >
              <User className="h-6 w-6 text-gray-900" />
            </button>
            <button 
              onClick={onCartClick} 
              className="p-2 hover:bg-gray-50 rounded-md min-h-[44px] min-w-[44px] flex items-center justify-center"
              aria-label="Cart"
            >
              <ShoppingBag className="h-6 w-6 text-gray-900" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 lg:hidden">
          <div className="fixed inset-y-0 left-0 w-full max-w-sm bg-white shadow-xl overflow-y-auto">
            <div className="p-4 border-b">
              <h2 className="text-lg font-medium">Menu</h2>
              <button
                onClick={() => setIsMenuOpen(false)}
                className="absolute top-4 right-4 p-2 hover:bg-gray-50 rounded-md"
              >
                Close
              </button>
            </div>

            <div className="py-4">
              <Link
                to="/"
                onClick={() => setIsMenuOpen(false)}
                className="block px-4 py-3 hover:bg-gray-50"
              >
                Home
              </Link>
              <Link
                to="/shipping"
                onClick={() => setIsMenuOpen(false)}
                className="block px-4 py-3 hover:bg-gray-50"
              >
                Shipping
              </Link>
              <a
                href="mailto:showponysales@gmail.com"
                className="block px-4 py-3 hover:bg-gray-50"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;