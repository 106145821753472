import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ProductFormData } from '../../../lib/types/product';
import { draftSchema } from '../../../lib/validation/product';
import Details from './Details';
import Materials from './Materials';
import Dimensions from './Dimensions';
import BasicInfo from './BasicInfo';
import ImageUploader from './ImageUploader';
import SaveButton from './buttons/SaveButtons';
import { toast } from 'sonner';

interface ProductUploaderProps {
  onSubmit: (data: ProductFormData) => Promise<void>;
  initialData?: Partial<ProductFormData>;
  isLoading?: boolean;
}

const ProductUploader: React.FC<ProductUploaderProps> = ({
  onSubmit,
  initialData,
  isLoading = false,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProductFormData>({
    resolver: zodResolver(draftSchema),
    defaultValues: {
      status: 'draft',
      itemNumber: initialData?.itemNumber || `ITEM-${Date.now()}`,
      title: initialData?.title || '',
      ...initialData,
    }
  });

  const handleFormSubmit = async (data: ProductFormData) => {
    try {
      // Always save as draft
      const formData: ProductFormData = {
        ...data,
        status: 'draft'
      };

      await onSubmit(formData);
      toast.success('Product saved as draft');
    } catch (error) {
      console.error('Form submission error:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to save product');
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-8">
      <BasicInfo 
        register={register} 
        errors={errors} 
        setValue={setValue}
        watch={watch}
      />
      
      <Details register={register} errors={errors} setValue={setValue} />
      <Materials control={control} errors={errors} />
      <Dimensions control={control} errors={errors} />
      <ImageUploader control={control} errors={errors} />

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <SaveButton
          isLoading={isLoading}
          isTitleEmpty={!watch('title')}
          onSave={() => {}}
        />
      </div>
    </form>
  );
};

export default ProductUploader;