import React from 'react';
import { Helmet } from 'react-helmet-async';
import { generateBreadcrumbSchema } from '../../lib/utils/seo';

interface CategorySEOProps {
  category: string;
  subcategory?: string;
  option?: string;
  productCount: number;
}

const CategorySEO: React.FC<CategorySEOProps> = ({ 
  category, 
  subcategory, 
  option,
  productCount 
}) => {
  const title = option || subcategory || category;
  const description = `Shop our curated collection of ${title.toLowerCase()} at SHOW PONY PALM BEACH. Browse ${productCount} unique vintage and antique pieces.`;

  const breadcrumbItems = [
    { name: 'Home', url: '/' },
    { name: category, url: `/shop/${category.toLowerCase()}` }
  ];

  if (subcategory) {
    breadcrumbItems.push({
      name: subcategory,
      url: `/shop/${category.toLowerCase()}/${subcategory.toLowerCase()}`
    });
  }

  if (option) {
    breadcrumbItems.push({
      name: option,
      url: `/shop/${category.toLowerCase()}/${subcategory?.toLowerCase()}/${option.toLowerCase()}`
    });
  }

  return (
    <Helmet>
      <title>{`${title} | SHOW PONY PALM BEACH`}</title>
      <meta name="description" content={description} />
      
      {/* OpenGraph Tags */}
      <meta property="og:title" content={`${title} | SHOW PONY PALM BEACH`} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://showponypalmbeach.com/shop/${category.toLowerCase()}`} />
      
      {/* Twitter Tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={`${title} | SHOW PONY PALM BEACH`} />
      <meta name="twitter:description" content={description} />
      
      {/* Breadcrumb Schema */}
      <script type="application/ld+json">
        {JSON.stringify(generateBreadcrumbSchema(breadcrumbItems))}
      </script>
      
      <link rel="canonical" href={`https://showponypalmbeach.com/shop/${category.toLowerCase()}`} />
    </Helmet>
  );
};

export default CategorySEO;