import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductUploader from '../../components/admin/products/ProductUploader';
import { ProductFormData } from '../../lib/types/product';
import { ProductService } from '../../lib/services/product.service';

const AddProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (data: ProductFormData) => {
    setIsLoading(true);
    setError(null);

    try {
      // Explicitly set status to draft
      const productData = {
        ...data,
        status: 'draft' as const // Force draft status
      };

      await ProductService.createProduct(productData);
      navigate('/admin/products');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create product');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl font-serif">Add New Product</h1>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-600 rounded-md">
          {error}
        </div>
      )}

      <ProductUploader onSubmit={handleSubmit} isLoading={isLoading} />
    </div>
  );
};

export default AddProduct;