import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Mail, Home, ChevronLeft, ChevronRight } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { Product, formatDimensions } from '../lib/types/product';
import ImageCarousel from '../components/ImageCarousel';
import ProductSEO from '../components/seo/ProductSEO';
import { useMediaQuery } from '../lib/utils/hooks/useMediaQuery';
import PaymentButtons from '../components/payment/PaymentButtons';

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const { data, error } = await supabase
          .from('products')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;
        setProduct(data);
      } catch (err) {
        console.error('Error fetching product:', err);
        setError('Failed to load product');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchProduct();
    }
  }, [id]);

  const handleContactClick = () => {
    if (!product) return;
    
    const subject = encodeURIComponent(`Inquiry about: ${product.title}`);
    const body = encodeURIComponent(`Hi, I'm interested in purchasing:\n\n${product.title}\nPrice: $${product.price}\n\nPlease provide more information about this item.`);
    window.location.href = `mailto:showponysales@gmail.com?subject=${subject}&body=${body}`;
  };

  const handleBackHome = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePrevImage = () => {
    if (product && currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleNextImage = () => {
    if (product && currentImageIndex < product.photos.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="animate-pulse">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div className="aspect-square bg-gray-200 rounded-lg"></div>
            <div className="space-y-4">
              <div className="h-8 bg-gray-200 rounded w-3/4"></div>
              <div className="h-6 bg-gray-200 rounded w-1/4"></div>
              <div className="h-32 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !product) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center">
          <p className="text-red-600">{error || 'Product not found'}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <ProductSEO product={product} />
      
      <div className="max-w-7xl mx-auto px-0 md:px-8 py-0 md:py-12">
        <div className="flex justify-between items-center mb-6 px-4 md:px-0">
          <button
            onClick={handleBackHome}
            className="flex items-center gap-2 px-4 py-2 text-sm bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
          >
            <Home className="h-4 w-4" />
            Back to Home
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="space-y-4">
            {isMobile ? (
              <ImageCarousel images={product.photos} title={product.title} />
            ) : (
              <>
                <div className="relative aspect-square bg-gray-100 rounded-lg overflow-hidden">
                  <img
                    src={product.photos[currentImageIndex]}
                    alt={product.title}
                    className="w-full h-full object-contain"
                  />
                  {product.photos.length > 1 && (
                    <>
                      <button
                        onClick={handlePrevImage}
                        disabled={currentImageIndex === 0}
                        className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 backdrop-blur-sm disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ChevronLeft className="w-6 h-6" />
                      </button>
                      <button
                        onClick={handleNextImage}
                        disabled={currentImageIndex === product.photos.length - 1}
                        className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 backdrop-blur-sm disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ChevronRight className="w-6 h-6" />
                      </button>
                    </>
                  )}
                </div>
                {product.photos.length > 1 && (
                  <div className="grid grid-cols-6 gap-2">
                    {product.photos.map((photo, index) => (
                      <button
                        key={index}
                        onClick={() => setCurrentImageIndex(index)}
                        className={`aspect-square rounded-md overflow-hidden border-2 ${
                          currentImageIndex === index ? 'border-black' : 'border-transparent'
                        }`}
                      >
                        <img
                          src={photo}
                          alt={`${product.title} - View ${index + 1}`}
                          className="w-full h-full object-cover object-center"
                        />
                      </button>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="space-y-6 px-4 md:px-0">
            <div>
              <h1 className="text-3xl font-serif mb-2">{product.title}</h1>
              <p className="text-2xl">${product.price.toLocaleString()}</p>
            </div>

            <div className="space-y-4">
              <PaymentButtons
                productId={product.id}
                title={product.title}
                price={product.price}
                image={product.photos[0]}
              />

              <button
                onClick={handleContactClick}
                className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-white border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
              >
                <Mail className="h-5 w-5" />
                Contact to Inquire
              </button>
            </div>

            <div className="prose max-w-none">
              <p>{product.description}</p>
            </div>

            <div className="border-t pt-6 space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <dt className="text-sm text-gray-500">Category</dt>
                  <dd className="text-sm font-medium">{product.category}</dd>
                </div>
                {product.subcategory && (
                  <div>
                    <dt className="text-sm text-gray-500">Subcategory</dt>
                    <dd className="text-sm font-medium">{product.subcategory}</dd>
                  </div>
                )}
                <div>
                  <dt className="text-sm text-gray-500">Condition</dt>
                  <dd className="text-sm font-medium">{product.condition}</dd>
                </div>
                {product.conditionDetails && (
                  <div>
                    <dt className="text-sm text-gray-500">Condition Details</dt>
                    <dd className="text-sm font-medium">{product.conditionDetails}</dd>
                  </div>
                )}
                {product.dimensions && (
                  <div>
                    <dt className="text-sm text-gray-500">Dimensions</dt>
                    <dd className="text-sm font-medium">
                      {formatDimensions(product.dimensions)}
                    </dd>
                  </div>
                )}
                {product.materials && product.materials.length > 0 && (
                  <div>
                    <dt className="text-sm text-gray-500">Materials</dt>
                    <dd className="text-sm font-medium">
                      {product.materials.join(', ')}
                    </dd>
                  </div>
                )}
                {product.designer && (
                  <div>
                    <dt className="text-sm text-gray-500">Designer</dt>
                    <dd className="text-sm font-medium">{product.designer}</dd>
                  </div>
                )}
                {product.manufacturer && (
                  <div>
                    <dt className="text-sm text-gray-500">Manufacturer</dt>
                    <dd className="text-sm font-medium">{product.manufacturer}</dd>
                  </div>
                )}
                {product.origin && (
                  <div>
                    <dt className="text-sm text-gray-500">Origin</dt>
                    <dd className="text-sm font-medium">{product.origin}</dd>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;