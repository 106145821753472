import React, { useState } from 'react';
import { useCartStore } from '../../lib/store/cart';
import { createCheckoutSession } from '../../lib/stripe';
import { toast } from 'sonner';
import CartHeader from './CartHeader';
import CartItems from './CartItems';
import CartFooter from './CartFooter';
import EmptyCart from './EmptyCart';
import CartOverlay from './CartOverlay';

interface CartProps {
  isOpen: boolean;
  onClose: () => void;
}

const Cart: React.FC<CartProps> = ({ isOpen, onClose }) => {
  const { items, total } = useCartStore();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleCheckout = async () => {
    try {
      setIsProcessing(true);
      await createCheckoutSession(items);
    } catch (error) {
      console.error('Checkout error:', error);
      toast.error('Failed to start checkout. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  if (!isOpen) return null;

  return (
    <CartOverlay onClose={onClose}>
      <div className="h-full flex flex-col bg-white shadow-xl">
        <CartHeader onClose={onClose} />
        <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
          {items.length === 0 ? <EmptyCart /> : <CartItems items={items} />}
        </div>
        {items.length > 0 && (
          <CartFooter
            total={total}
            isProcessing={isProcessing}
            onCheckout={handleCheckout}
            onClose={onClose}
          />
        )}
      </div>
    </CartOverlay>
  );
};

export default Cart;