import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { X, ChevronDown, ChevronRight, Plus } from 'lucide-react';
import { ProductFormData } from '../../../lib/types/product';
import { materialCategories } from '../../../lib/constants/materials';

interface MaterialsProps {
  control: Control<ProductFormData>;
  errors: any;
}

const Materials: React.FC<MaterialsProps> = ({ control, errors }) => {
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-medium mb-4">Materials</h2>

      <Controller
        name="materials"
        control={control}
        render={({ field }) => (
          <div className="space-y-4">
            {/* Selected Materials */}
            {field.value?.length > 0 && (
              <div className="flex flex-wrap gap-1.5 p-2 bg-gray-50 rounded-md">
                {field.value.map((material) => (
                  <span
                    key={material}
                    className="inline-flex items-center gap-1 px-2 py-0.5 bg-white border rounded-full text-sm"
                  >
                    {material}
                    <button
                      type="button"
                      onClick={() => {
                        field.onChange(field.value.filter(m => m !== material));
                      }}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      <X className="h-3 w-3" />
                    </button>
                  </span>
                ))}
              </div>
            )}

            {/* Material Categories */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-1">
              {Object.entries(materialCategories).map(([category, materials]) => (
                <div key={category} className="border-b last:border-b-0 md:even:border-b-0">
                  <div className="flex items-center justify-between w-full py-1.5 px-2">
                    <button
                      type="button"
                      onClick={() => setExpandedCategory(expandedCategory === category ? null : category)}
                      className="flex items-center gap-2 hover:bg-gray-50 rounded px-2 py-1 flex-grow text-left"
                    >
                      {expandedCategory === category ? (
                        <ChevronDown className="h-4 w-4 text-gray-400" />
                      ) : (
                        <ChevronRight className="h-4 w-4 text-gray-400" />
                      )}
                      <span className="font-medium">{category}</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const newMaterials = field.value?.includes(category)
                          ? field.value.filter(m => m !== category)
                          : [...(field.value || []), category];
                        field.onChange(newMaterials);
                      }}
                      className={`flex items-center gap-1 px-3 py-1 rounded-full text-sm transition-colors ${
                        field.value?.includes(category)
                          ? 'bg-black text-white hover:bg-gray-800'
                          : 'border border-gray-300 hover:bg-gray-50'
                      }`}
                    >
                      {field.value?.includes(category) ? (
                        'Selected'
                      ) : (
                        <>
                          <Plus className="h-3 w-3" />
                          Select
                        </>
                      )}
                    </button>
                  </div>

                  {expandedCategory === category && (
                    <div className="grid grid-cols-2 gap-x-4 px-8 py-1">
                      {materials.slice(1).map((material) => (
                        <div key={material} className="flex items-center justify-between py-1 text-sm">
                          <span className="text-gray-600">{material}</span>
                          <button
                            type="button"
                            onClick={() => {
                              const newMaterials = field.value?.includes(material)
                                ? field.value.filter(m => m !== material)
                                : [...(field.value || []), material];
                              field.onChange(newMaterials);
                            }}
                            className={`flex items-center gap-1 px-2 py-0.5 rounded-full text-xs transition-colors ${
                              field.value?.includes(material)
                                ? 'bg-black text-white hover:bg-gray-800'
                                : 'border border-gray-300 hover:bg-gray-50'
                            }`}
                          >
                            {field.value?.includes(material) ? (
                              'Selected'
                            ) : (
                              <>
                                <Plus className="h-2.5 w-2.5" />
                                Select
                              </>
                            )}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>

            {errors.materials && (
              <p className="text-sm text-red-600 mt-1">{errors.materials.message}</p>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default Materials;