import React from 'react';
import { X, GripVertical, Wand2 } from 'lucide-react';
import ImageRotator from './ImageRotator';

interface ImagePreviewProps {
  url: string;
  index: number;
  isMain: boolean;
  isProcessing?: boolean;
  onDelete: () => void;
  onRotate: () => void;
  onRemoveBackground: () => void;
  dragHandleProps?: any;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ 
  url, 
  index, 
  isMain,
  isProcessing,
  onDelete,
  onRotate,
  onRemoveBackground,
  dragHandleProps 
}) => {
  return (
    <div className="relative aspect-square bg-gray-100 rounded-lg overflow-hidden group">
      <div className="w-full h-full flex items-center justify-center">
        <img
          src={url}
          alt={`Preview ${index + 1}`}
          className="max-w-full max-h-full object-contain"
          loading="lazy"
        />
      </div>
      
      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-opacity" />
      
      <div className="absolute top-2 right-2 flex gap-2">
        {isMain && (
          <button
            onClick={onRemoveBackground}
            disabled={isProcessing}
            className="p-1.5 bg-white rounded-full shadow-md hover:bg-gray-100 opacity-0 group-hover:opacity-100 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
            title="Remove Background (Main Photo Only)"
          >
            <Wand2 className="h-4 w-4" />
          </button>
        )}
        <ImageRotator onRotate={onRotate} disabled={isProcessing} />
        <button
          type="button"
          onClick={onDelete}
          className="p-1.5 bg-white rounded-full shadow-md hover:bg-gray-100 opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <X className="h-4 w-4" />
        </button>
      </div>
      
      <div
        {...dragHandleProps}
        className="absolute top-2 left-2 p-1.5 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity cursor-grab"
      >
        <GripVertical className="h-4 w-4" />
      </div>

      {isMain && (
        <div className="absolute bottom-2 left-2 px-2 py-1 bg-black text-white text-xs rounded-full">
          Main Photo
        </div>
      )}
    </div>
  );
};

export default ImagePreview;