import React from 'react';
import { Product } from '../../../../lib/types/product';
import ProductTableRow from './ProductTableRow';

interface ProductTableBodyProps {
  products: Product[];
  onDelete: (product: Product) => void;
  onRefresh: () => void;
}

const ProductTableBody: React.FC<ProductTableBodyProps> = ({ products, onDelete, onRefresh }) => {
  return (
    <tbody className="bg-white divide-y divide-gray-200">
      {products.map((product) => (
        <ProductTableRow
          key={product.id}
          product={product}
          onDelete={onDelete}
          onRefresh={onRefresh}
        />
      ))}
    </tbody>
  );
};

export default ProductTableBody;