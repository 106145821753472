import { z } from 'zod';
import { conditions, periods, styles } from '../types/product';

// Draft schema - only title required
export const draftSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  itemNumber: z.string().optional(),
  status: z.enum(['draft', 'published']).default('draft'),
  // All other fields optional
  description: z.string().optional(),
  price: z.number().optional(),
  category: z.string().optional(),
  subcategory: z.string().optional(),
  option: z.string().optional(),
  condition: z.enum(conditions).optional(),
  conditionDetails: z.string().optional(),
  period: z.enum(periods).optional(),
  style: z.enum(styles).optional(),
  designer: z.string().optional(),
  manufacturer: z.string().optional(),
  origin: z.string().optional(),
  materials: z.array(z.string()).optional(),
  photos: z.array(z.any()).optional(),
  dimensions: z.object({
    width: z.number().optional(),
    height: z.number().optional(),
    depth: z.number().optional(),
    seatHeight: z.number().optional(),
    unit: z.enum(['in', 'cm']).default('in')
  }).optional()
});

// Publish schema - all required fields
export const publishSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  price: z.number().min(0, 'Price must be greater than 0'),
  category: z.string().min(1, 'Category is required'),
  condition: z.enum(conditions),
  materials: z.array(z.string()).min(1, 'At least one material is required'),
  photos: z.array(z.any()).min(1, 'At least one photo is required'),
  itemNumber: z.string(),
  status: z.enum(['draft', 'published']).default('published'),
  // Optional fields
  subcategory: z.string().optional(),
  option: z.string().optional(),
  conditionDetails: z.string().optional(),
  period: z.enum(periods).optional(),
  style: z.enum(styles).optional(),
  designer: z.string().optional(),
  manufacturer: z.string().optional(),
  origin: z.string().optional(),
  dimensions: z.object({
    width: z.number().optional(),
    height: z.number().optional(),
    depth: z.number().optional(),
    seatHeight: z.number().optional(),
    unit: z.enum(['in', 'cm']).default('in')
  }).optional()
});

export type DraftProduct = z.infer<typeof draftSchema>;
export type PublishProduct = z.infer<typeof publishSchema>;