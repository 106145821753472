import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Layout from './components/layout/Layout';
import { ScrollManager } from './components/ScrollManager';
import Login from './pages/admin/Login';
import Dashboard from './pages/admin/Dashboard';
import AddProduct from './pages/admin/AddProduct';
import EditProduct from './pages/admin/EditProduct';
import ProductList from './pages/admin/ProductList';
import AuthGuard from './components/auth/AuthGuard';
import Hero from './components/Hero';
import Shop from './pages/Shop';
import ProductDetail from './pages/ProductDetail';
import Shipping from './components/Shipping';
import FeaturedProducts from './components/FeaturedProducts';
import Checkout from './pages/checkout/Checkout';
import Success from './pages/checkout/Success';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

function App() {
  return (
    <>
      <ScrollManager />
      <Elements stripe={stripePromise}>
        <Routes>
          {/* Admin Routes */}
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin" element={<AuthGuard />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="products" element={<ProductList />} />
            <Route path="products/new" element={<AddProduct />} />
            <Route path="products/edit/:id" element={<EditProduct />} />
          </Route>

          {/* Public Routes */}
          <Route path="/" element={
            <Layout>
              <div className="space-y-0">
                <Hero />
                <FeaturedProducts />
              </div>
            </Layout>
          } />
          
          <Route path="/shop" element={
            <Layout>
              <Shop />
            </Layout>
          } />
          
          <Route path="/shop/:category" element={
            <Layout>
              <Shop />
            </Layout>
          } />
          
          <Route path="/product/:id" element={
            <Layout>
              <ProductDetail />
            </Layout>
          } />
          
          <Route path="/shipping" element={
            <Layout>
              <Shipping />
            </Layout>
          } />

          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/success" element={<Success />} />
        </Routes>
      </Elements>
    </>
  );
}

export default App;