import React from 'react';
import { UseFormRegister, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { ProductFormData } from '../../../lib/types/product';
import ConditionSection from './details/ConditionSection';
import StyleSection from './details/StyleSection';
import OriginSection from './details/OriginSection';

interface DetailsProps {
  register: UseFormRegister<ProductFormData>;
  errors: FieldErrors<ProductFormData>;
  setValue: UseFormSetValue<ProductFormData>;
}

const Details: React.FC<DetailsProps> = ({ register, errors, setValue }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-medium mb-6">Product Details</h2>
      
      <div className="space-y-8">
        <ConditionSection register={register} errors={errors} setValue={setValue} />
        <StyleSection register={register} />
        <OriginSection register={register} setValue={setValue} />
      </div>
    </div>
  );
};

export default Details;