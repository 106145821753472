import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'sonner';
import { ProductFormData } from '../../../../lib/types/product';
import { uploadImage } from '../../../../lib/utils/storage';
import { removeBackground } from '../../../../lib/utils/photoroom';
import { rotateImage } from '../../../../lib/utils/image/rotate';
import ImagePreview from './ImagePreview';
import UploadProgress from './UploadProgress';
import DropZone from './DropZone';

interface ImageUploaderProps {
  control: Control<ProductFormData>;
  errors: any;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ control, errors }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDrop = async (acceptedFiles: File[], onChange: (photos: string[]) => void, currentPhotos: string[]) => {
    if (isUploading) return;
    
    if (currentPhotos.length + acceptedFiles.length > 10) {
      toast.error('Maximum 10 images allowed');
      return;
    }

    setIsUploading(true);

    try {
      const newPhotos: string[] = [];

      for (const file of acceptedFiles) {
        setUploadingFiles(prev => [...prev, file.name]);
        try {
          const url = await uploadImage(file);
          newPhotos.push(url);
          setUploadingFiles(prev => prev.filter(name => name !== file.name));
        } catch (error) {
          toast.error(`Failed to upload ${file.name}`);
          console.error(`Error uploading ${file.name}:`, error);
        }
      }

      if (newPhotos.length > 0) {
        onChange([...currentPhotos, ...newPhotos]);
        toast.success('Images uploaded successfully');
      }
    } catch (err) {
      toast.error('Failed to upload images');
    } finally {
      setIsUploading(false);
      setUploadingFiles([]);
    }
  };

  const handleDragEnd = (result: any, onChange: (photos: string[]) => void, currentPhotos: string[]) => {
    if (!result.destination) return;

    const items = Array.from(currentPhotos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    onChange(items);
  };

  const handleRotateImage = async (index: number, onChange: (photos: string[]) => void, currentPhotos: string[]) => {
    setIsProcessing(true);
    try {
      const photoUrl = currentPhotos[index];
      
      // Download the image
      const response = await fetch(photoUrl);
      const blob = await response.blob();
      const file = new File([blob], `image-${index}.jpg`, { type: 'image/jpeg' });
      
      // Rotate the image
      const rotatedFile = await rotateImage(file, 90);
      
      // Upload rotated image
      const newUrl = await uploadImage(rotatedFile);
      
      // Update photos array
      const newPhotos = [...currentPhotos];
      newPhotos[index] = newUrl;
      onChange(newPhotos);
      
      toast.success('Image rotated successfully');
    } catch (error) {
      console.error('Rotation error:', error);
      toast.error('Failed to rotate image');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRemoveBackground = async (photoUrl: string, index: number, onChange: (photos: string[]) => void, currentPhotos: string[]) => {
    if (isProcessing || index !== 0) return;
    setIsProcessing(true);

    try {
      const response = await fetch(photoUrl);
      const blob = await response.blob();
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
      
      const processedFile = await removeBackground(file);
      const newUrl = await uploadImage(processedFile);
      
      const newPhotos = [...currentPhotos];
      newPhotos[index] = newUrl;
      onChange(newPhotos);
      
      toast.success('Background removed successfully');
    } catch (error) {
      console.error('Background removal error:', error);
      toast.error('Failed to remove background');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm space-y-6">
      <h2 className="text-lg font-medium border-b pb-4">Product Photos</h2>

      <Controller
        name="photos"
        control={control}
        render={({ field: { onChange, value = [] } }) => (
          <div className="space-y-6">
            <DropZone
              onDrop={(files) => handleDrop(files, onChange, value)}
              isUploading={isUploading}
            />

            {value.length > 0 && (
              <DragDropContext onDragEnd={(result) => handleDragEnd(result, onChange, value)}>
                <Droppable droppableId="photos" direction="horizontal">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="grid grid-cols-2 md:grid-cols-4 gap-4"
                    >
                      {value.map((photo, index) => (
                        <Draggable key={photo} draggableId={photo} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <ImagePreview
                                url={photo}
                                index={index}
                                isMain={index === 0}
                                onDelete={() => {
                                  const newPhotos = [...value];
                                  newPhotos.splice(index, 1);
                                  onChange(newPhotos);
                                }}
                                onRotate={() => handleRotateImage(index, onChange, value)}
                                onRemoveBackground={() => handleRemoveBackground(photo, index, onChange, value)}
                                isProcessing={isProcessing && index === 0}
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}

            {uploadingFiles.length > 0 && (
              <div className="space-y-2">
                {uploadingFiles.map((fileName) => (
                  <UploadProgress key={fileName} fileName={fileName} />
                ))}
              </div>
            )}

            {errors.photos && (
              <p className="text-red-500 text-sm mt-2">{errors.photos.message}</p>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default ImageUploader;