import { Category } from './types';

export const categories: Category[] = [
  {
    name: 'Decor',
    image: 'https://res.cloudinary.com/graphichut/image/upload/v1732029544/f9fumbzbxxlgi9i8f9xb.jpg',
    subcategories: [
      {
        name: 'Organization Accessories',
        options: [
          'Baskets',
          'Boxes',
          'Coat & Hat Racks',
          'Hooks',
          'Luggage Racks',
          'Magazine Racks',
          'Umbrella Stands',
          'Valets',
          'Wastebaskets & Trashcans'
        ]
      },
      {
        name: 'Pedestals & Columns'
      },
      {
        name: 'Room Accents & Accessories',
        options: [
          'Ashtrays & Catchalls',
          'Bookends',
          'Books',
          'Decorative Bowls',
          'Decorative Objects',
          'Desk Sets',
          'Easels',
          'Fireplace Accessories',
          'Kitchen Accessories',
          'Match Strikers',
          'Mirrors',
          'Models & Figurines',
          'Obelisks',
          'Paper Weights',
          'Picture Frames',
          'Plant Stands',
          'Screens & Room Dividers',
          'Textile Art',
          'Trays',
          'Vessels & Vases',
          'Decorative Brackets',
          'Decorative Plates',
          'Maps',
          'Sculptural Wall Objects',
          'Signs',
          'Wall Pockets',
          'Textiles',
          'Throw Pillows',
          'Quilts'
        ],
        subcategories: {
          'Vessels & Vases': [
            'Bottles & Jars & Jugs',
            'Cachepot',
            'Ginger Jars',
            'Urns',
            'Vases'
          ]
        }
      }
    ]
  },
  {
    name: 'Fine Art',
    image: 'https://res.cloudinary.com/graphichut/image/upload/v1732065545/qfde18llwgew968bubxf.jpg',
    subcategories: [
      {
        name: 'Collage'
      },
      {
        name: 'Drawings'
      },
      {
        name: 'Installation'
      },
      {
        name: 'Paintings'
      },
      {
        name: 'Photography'
      },
      {
        name: 'Prints',
        options: [
          'Ephemera & Advertisements',
          'Original Prints',
          'Posters',
          'Reproduction Prints'
        ]
      },
      {
        name: 'Sculpture'
      }
    ]
  },
  {
    name: 'Furniture',
    image: 'https://res.cloudinary.com/graphichut/image/upload/v1732068701/xleitabpfzulmmgstkrc.jpg',
    subcategories: [
      {
        name: 'Beds',
        options: [
          'Bedframes',
          'Headboards'
        ]
      },
      {
        name: 'Casegoods & Storage',
        options: [
          'Armoires, Wardrobes & Linen Presses',
          'Bar Carts & Dry Bars',
          'China & Display Cabinets',
          'Console Table with Mirror Set',
          'Credenzas & Sideboards',
          'Dressers & Chests of Drawers',
          'Nightstands',
          'Shelving',
          'Storage Cabinets & Cupboards',
          'Trunks & Chests',
          'Vanities',
          'Vanity & Mirror Sets'
        ],
        subcategories: {
          'Dressers & Chests of Drawers': [
            'Chests of Drawers',
            'Chiffoniers',
            'Commodes',
            'Dresser Sets',
            'Highboys',
            'Lowboys',
            'Standard Dressers'
          ]
        }
      },
      {
        name: 'Seating',
        options: [
          'Accent Chairs',
          'Benches',
          'Chair & Ottoman Sets',
          'Dining Chairs',
          'Lounge Chair',
          'Office Chairs',
          'Ottomans & Footstools',
          'Sofas',
          'Stools'
        ],
        subcategories: {
          'Sofas': [
            'Chaises',
            'Daybeds',
            'Loveseats',
            'Sectionals',
            'Settees',
            'Sofa Sets',
            'Standard Sofas'
          ]
        }
      },
      {
        name: 'Tables',
        options: [
          'Accent Tables',
          'Coffee Tables',
          'Conference Tables',
          'Console Tables',
          'Desks',
          'Dining Sets',
          'Dining Tables',
          'Islands & Butcher Blocks'
        ]
      }
    ]
  },
  {
    name: 'Lighting',
    image: 'https://res.cloudinary.com/graphichut/image/upload/v1732032802/xjcerqusaurgqcrlqnug.jpg',
    subcategories: [
      {
        name: 'Chandeliers'
      },
      {
        name: 'Lamp Shades'
      },
      {
        name: 'Lamps',
        options: [
          'Desk Lamps',
          'Finials',
          'Floor Lamps',
          'Table Lamps',
          'Task Lighting'
        ]
      },
      {
        name: 'Lanterns'
      },
      {
        name: 'Outdoor Lighting'
      },
      {
        name: 'Sconces'
      }
    ]
  },
  {
    name: 'Outdoor',
    image: 'https://res.cloudinary.com/graphichut/image/upload/v1732066717/yb1ogr4tbubtr7iinqoa.jpg',
    subcategories: [
      {
        name: 'Outdoor Accents',
        options: [
          'Fountains',
          'Garden Ornaments',
          'Outdoor Lanterns',
          'Patio Umbrellas',
          'Planters',
          'Statues'
        ]
      },
      {
        name: 'Patio & Garden Furniture',
        options: [
          'Outdoor Seating',
          'Outdoor Tables'
        ]
      }
    ]
  },
  {
    name: 'Tableware & Barware',
    image: 'https://res.cloudinary.com/graphichut/image/upload/v1732630548/vintage-boho-studio-pottery-tea-set-9-pieces-2836_bov3vv.webp',
    subcategories: [
      {
        name: 'Bar Tools',
        options: [
          'Bottle Openers',
          'Cocktail Shakers',
          'Wine Bottle Holders',
          'Wine Coasters'
        ]
      },
      {
        name: 'Carafes & Decanters'
      },
      {
        name: 'Coasters'
      },
      {
        name: 'Coffee & Tea Service',
        options: [
          'Coffee Cups',
          'Coffee Sets',
          'Teacups',
          'Tea Pots',
          'Tea Sets'
        ]
      },
      {
        name: 'Dinnerware',
        options: [
          'China',
          'Glasses',
          'Silver'
        ]
      },
      {
        name: 'Ice Buckets'
      },
      {
        name: 'Mugs & Cups'
      },
      {
        name: 'Napkin Rings'
      },
      {
        name: 'Pitchers'
      },
      {
        name: 'Salt & Pepper Shakers'
      },
      {
        name: 'Salt Cellars'
      },
      {
        name: 'Serveware',
        options: [
          'Platters',
          'Serving Bowls',
          'Serving Dishes & Pieces',
          'Serving Sets',
          'Serving Utensils'
        ]
      },
      {
        name: 'Table Linens'
      },
      {
        name: 'Trivets'
      },
      {
        name: 'Wine Coolers'
      },
      {
        name: 'Wine Racks'
      }
    ]
  }
];