import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { Product } from '../lib/types/product';
import { formatDimensions } from '../lib/utils/format/dimensions';

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const navigate = useNavigate();

  const handleProductClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'instant' });
    navigate(`/product/${product.id}`);
  };

  return (
    <div className="group relative">
      <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
        <Link to={`/product/${product.id}`} onClick={handleProductClick}>
          <div className="relative w-full h-full">
            <img
              src={product.photos[0]}
              alt={product.title}
              className="absolute inset-0 w-full h-full object-contain group-hover:scale-105 transition-transform duration-300"
              loading="lazy"
            />
          </div>
        </Link>
      </div>
      <div className="mt-4 space-y-2">
        <Link to={`/product/${product.id}`} onClick={handleProductClick}>
          <h3 className="text-lg font-medium text-gray-900 group-hover:underline line-clamp-2">
            {product.title}
          </h3>
        </Link>
        <div className="space-y-1">
          <p className="text-gray-500">{product.category}</p>
          {product.dimensions && (
            <p className="text-sm text-gray-500">{formatDimensions(product.dimensions)}</p>
          )}
        </div>
        <p className="text-lg font-medium text-gray-900">${product.price.toLocaleString()}</p>
      </div>
    </div>
  );
};

export default ProductCard;