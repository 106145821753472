import React, { useState } from 'react';
import { ShoppingBag, Loader2 } from 'lucide-react';
import { createCheckoutSession } from '../../lib/stripe/checkout';
import { toast } from 'sonner';

interface PaymentButtonsProps {
  productId: string;
  title: string;
  price: number;
  image: string;
}

const PaymentButtons: React.FC<PaymentButtonsProps> = ({ productId, title, price, image }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleBuyNow = async () => {
    setIsProcessing(true);
    try {
      await createCheckoutSession([{
        id: productId,
        title,
        price,
        quantity: 1,
        image
      }]);
    } catch (error) {
      console.error('Payment error:', error);
      toast.error('Failed to process payment. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <button
      onClick={handleBuyNow}
      disabled={isProcessing}
      className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
    >
      {isProcessing ? (
        <>
          <Loader2 className="h-5 w-5 animate-spin" />
          Processing...
        </>
      ) : (
        <>
          <ShoppingBag className="h-5 w-5" />
          Buy Now - ${price.toLocaleString()}
        </>
      )}
    </button>
  );
};

export default PaymentButtons;