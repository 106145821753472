import React from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { ProductFormData } from '../../../../lib/types/product';

interface OriginSectionProps {
  register: UseFormRegister<ProductFormData>;
  setValue: UseFormSetValue<ProductFormData>;
}

const OriginSection: React.FC<OriginSectionProps> = ({ register, setValue }) => {
  const handleMarkUnknown = (field: keyof ProductFormData) => {
    setValue(field, 'unknown', { shouldDirty: true });
  };

  return (
    <div className="grid grid-cols-3 gap-6">
      <div>
        <label className="block text-sm font-medium mb-1">Designer</label>
        <div className="space-y-2">
          <input
            type="text"
            {...register('designer')}
            className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
            placeholder="Enter designer name"
          />
          <button
            type="button"
            onClick={() => handleMarkUnknown('designer')}
            className="px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
          >
            Mark as Unknown
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Manufacturer</label>
        <div className="space-y-2">
          <input
            type="text"
            {...register('manufacturer')}
            className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
            placeholder="Enter manufacturer name"
          />
          <button
            type="button"
            onClick={() => handleMarkUnknown('manufacturer')}
            className="px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
          >
            Mark as Unknown
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Origin</label>
        <div className="space-y-2">
          <input
            type="text"
            {...register('origin')}
            className="w-full px-4 py-2 border rounded-md focus:ring-black focus:border-black"
            placeholder="Enter country of origin"
          />
          <button
            type="button"
            onClick={() => handleMarkUnknown('origin')}
            className="px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
          >
            Mark as Unknown
          </button>
        </div>
      </div>
    </div>
  );
};

export default OriginSection;