export interface Dimensions {
  width?: number;
  height?: number;
  depth?: number;
  seatHeight?: number;
  unit?: 'in' | 'cm';
}

export const formatDimensions = (dimensions?: Dimensions): string => {
  if (!dimensions) return '';
  
  const { width, height, depth, seatHeight, unit = 'in' } = dimensions;
  const parts: string[] = [];

  if (width) parts.push(`W: ${width}${unit}`);
  if (depth) parts.push(`D: ${depth}${unit}`);
  if (height) parts.push(`H: ${height}${unit}`);
  if (seatHeight) parts.push(`Seat H: ${seatHeight}${unit}`);

  return parts.join(' × ');
};